<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      "My Contracts": "Meus Contratos",

      Send: "Enviar",
      "Choose the file": "Escolha o arquivo",
      Receipts: "Comprovantes",
      "The file has been send": "O arquivo foi enviado com sucesso!",
    },
    es: {
      "My Contracts": "Mis Contratos",

      Send: "Enviar",
      "Choose the file": "Elige el archivo",
      Receipts: "Comprovantes",
      "The file has been send": "El archivo ha sido enviado",
    },
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      contract: {
        loading: true,

        id: this.$route.params.id,
        code: "",
        status: "",
        date: {
          start: "",
          end: "",
        },
        value: "0.00",
      },
    };
  },
  methods: {
    getContract() {
      api.get("contract/" + this.contract.id).then((response) => {
        if (response.data.status == "success") {
          this.contract = response.data.contract;
          this.contract.loading = false;

          this.getFile()
        }
      });
    },
    getFile: function() {
      this.proof.loading = true;

      api
        .get("store/orders/file/" + this.contract.order.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.proof.list = response.data.files;
          } else {
            this.proof.list = null;
          }
        })
        .catch((error) => {
          this.proof.errored = error;
          this.proof.loading = false;
        })
        .finally(() => {
          this.proof.loading = false;
        });
    },
  },
  mounted() {
    this.getContract();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("My Contracts") }}</h4>
        </div>
      </div>
    </div>

    <div v-if="contract.loading" class="text-center">
      <b-spinner
        small
        class="ml-2 align-middle"
        variant="dark"
        role="status"
      ></b-spinner>
    </div>
    <div v-else>
      <div class="row justify-content-between">
        <div class="col-12">
          <div class="card contract text-dark">
            <div class="card-body pb-2">
              <div class="row mb-2">
                <div class="col-md-4 contract-code mb-2">
                  <small>CÓDIGO</small><br />
                  #{{ contract.code }}
                </div>
              </div>
              <div class="border-top pt-3 pb-2 row">
                <div class="col-md-4 contract-code mb-2">
                  <small>VALOR</small><br />
                  {{ contract.value | currency }}
                </div>
                <div class="col-md-4 contract-status mb-2">
                  <small>STATUS</small><br />
                  <span
                    v-if="contract.status == 'pending'"
                    class="badge badge-danger p-2 font-size-13 text-uppercase"
                    >Pendente</span
                  >
                  <span
                    v-else-if="contract.status == 'inactive'"
                    class="badge badge-primary p-2 font-size-13 text-uppercase"
                    >Inativo</span
                  >
                  <span
                    v-else-if="contract.status == 'active'"
                    class="badge badge-success p-2 font-size-13 text-uppercase"
                    >Ativo</span
                  >
                  <span
                    v-else-if="contract.status == 'closed'"
                    class="badge badge-dark p-2 font-size-13 text-uppercase"
                    >Encerrado</span
                  >
                  <span
                    v-else-if="contract.status == 'deleted'"
                    class="badge badge-danger p-2 font-size-13 text-uppercase"
                    >Cancelado</span
                  >
                </div>
                <div class="col-md-4 contract-status mb-2">
                  <small>ASSINTURA</small><br />
                  <span
                    v-if="contract.sign == 'pending'"
                    class="badge badge-danger p-2 font-size-13 text-uppercase"
                    >Pendente</span
                  >
                  <span
                    v-else-if="contract.sign == 'signed'"
                    class="badge badge-success p-2 font-size-13 text-uppercase"
                    >Assinado</span
                  >
                  <span
                    v-else-if="contract.sign == 'refused'"
                    class="badge badge-danger p-2 font-size-13 text-uppercase"
                    >Cancelado</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.contract {
  border-left: solid 5px #010101;
}

.table-profit {
  line-height: 15px;
}
.table-profit th,
.table-profit td {
  white-space: nowrap;
}
.table-year th {
  background: #bebfc1;
}
.table-month th {
  background: #d2d3d4;
}

.token input {
  margin: 0 2px;
  width: 40px;
  height: 40px;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
</style>
